// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import Components from 'components/CmsComponents/';
import FeaturedMarketing from 'components/featuredMarketing';
import Cards from 'components/units/unitPages/unit/services';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  section {
    div {
      h1 {
        @media ${device.tablet} {
          width: 30rem;
        }
        @media ${device.mobile} {
          width: auto;
        }
      }
      ul li {
        height: auto;

        @media (min-width: 320px) {
          width: 100%;
        }
        @media (min-width: 390px) {
          width: 90%;
        }
        @media (min-width: 540px) {
          width: 93%;
        }
        @media (min-width: 820px) {
          width: 90%;
          margin-left: 5%;
        }

        h3 {
          width: 100%;
          margin-bottom: 1.25rem;
          @media ${device.mobile320} {
            width: 90%;
          }
        }
        p {
          margin-bottom: 1.25rem;
          em {
            font-style: normal;
            font-weight: 600;
          }
          :last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: 30px;
    }

    @media ${device.mobile} {
      margin-bottom: 0;
      margin-top: -50px;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: -20px;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop, components) => (
  <>
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / 9'}>
        <FeaturedMarketing
          markdown={getMarkdown(page, 'ckl9i17hc6p6j0a22epvh8jox', true)}
          fragments={getMarkdown(page, 'ckl9i17hc6p6j0a22epvh8jox')}
          breadCrumb={getMarkdown(page, 'ckl9hrgeg6uag0a70l88lk7lt', true)}
          isProntoAtendimento
          boxInfoHeightLap="auto"
          containerNoBorderBottomLap
          noButtons
          titleWidthDesk="100%"
          titleWidthLap="100%"
          titleWidthTab="100%"
          titleWidthMob="100%"
          boxInfoWidthDesk="100%"
          boxInfoTextWidthDesk="100%"
          boxInfoTextWidthMobile="100%"
          containerNoBorderBottomDesk
          marginTopBoxInfoMob="1.9375rem"
        />
      </Part>
    </GridContainer>
    {components.length >= 1 &&
      components?.map(component => {
        return Components(component);
      })}
  </>
);

const ReadyToAttend = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);
  const components = page[0]?.components ?? [];
  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  return (
    <Layout>
      <SEO dataSeo={page} />
      {renderPage(page, isDesktop, components)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckl9hnfc86o800a22gcw7v0ow" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename

                  ... on GraphCMS_SimpleContent {
                    id
                    name
                    type
                    title
                    backgroundColor {
                      hex
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                      singletexts
                      assetpickerMultiple {
                        handle
                        width
                        height
                      }
                    }
                  }
                }

                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <ReadyToAttend page={response.gcms.site.pages} />;
      }}
    />
  );
};
